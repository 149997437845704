import React from "react"
import styled from "@emotion/styled"

import breakpoints from "../utils/breakpoints"

const Wrapper = styled.main`
  margin: 0 1rem;
  border-radius: 5px;

  ${breakpoints[1]} {
    background: linear-gradient(
      to bottom,
      rgba(236, 248, 251, 0.6) 0%,
      rgba(202, 233, 247, 0.6) 100%
    );
    display: flex;
    border: 1px solid #91cee7;

    padding: 0.5rem 2rem 1rem 2rem;
  }
`

const ContainerMain = props => <Wrapper>{props.children}</Wrapper>

export default ContainerMain
