import React from "react"
import styled from "@emotion/styled"
import breakpoints from "../utils/breakpoints"


// import mySunPath from "../svg/sun.svg"

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  ${breakpoints[3]} {
    max-width: 1200px;
  }
  position: relative;
  z-index: 2;
`

const OuterWrapper = styled.div`
  width: 100%;

  background: #afdbec; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #afdbec 0%,
    #e0f1f8 1100px
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #afdbec 0%,
    #e0f1f8 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #afdbec 0%,
    #e0f1f8 1100px
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#afdbec', endColorstr='#e0f1f8',GradientType=0 ); /* IE6-9 */

  &:after {
    content: "";
    background-size: 1600px 600px;
    background-image: url(/static/sun.svg);
    background-position:  center bottom;
    background-repeat: no-repeat;
    height: 750px;
    display: block;
    margin-top: -550px;
    position: relative;
    z-index: 1;
  }

  ${breakpoints[0]} {
    &:after {
      // background-size: auto auto;
    }
  }

`

const Container = props => (
  <OuterWrapper>
    <Wrapper>{props.children}</Wrapper>
  </OuterWrapper>
)

export default Container
