import React from "react"

import { Link } from "gatsby"
import PropTypes from "prop-types"
import { FaBars } from 'react-icons/fa';
import styled from "@emotion/styled"
import breakpoints from "../utils/breakpoints"

const Wrapper = styled.header`
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: ${props => props.theme.colors.primary};

  ${breakpoints[1]} {
    background: transparent;
  }
`

const H1 = styled.h1`

  padding: 0 1rem;
  flex: 1;

  a {
    color: white;
  }

  // Extra Small
  @media (max-width: 300px) {
    display: none;
  }

  ${breakpoints[1]} {
    align-self: center;
    display: flex;
    width: auto;

    a {
      color: ${props => props.theme.colors.primary};
    }
  }
`

const Nav = styled.nav`

  // Mobile and Tablet
  @media (max-width: 767px) {

    max-height: 6rem;
    transition: max-height 0.25s ease-in;
    overflow:hidden;

    flex: 1 1 100%;
    width: 100%;
    background: #444;
    z-index: 3000;
    position: relative;
    box-shadow: inset 0px 7px 3px -3px rgba(50, 50, 50, 0.5);
    display: flex;

    &.closed {
      max-height: 0;
      transition: max-height 0.15s ease-out;
      overflow: hidden;
    }
    
    &:after{
      content: '';
      height: 7px;
      position: absolute;
      bottom:0;
      width:100%;
      box-shadow: inset 0px -7px 3px -3px rgba(50, 50, 50, 0.5);
    }

    ul {
      padding: 0rem;
      width: 100%;

      li {
        width:100%;
        border-bottom: 1px solid #656565;

        a {

        padding: 0.75rem 1rem;
        display:block;
        }

      }
    }
  }

  ${breakpoints[1]} {
    margin: 1rem 0;
    display: flex;
    align-self: center;
    width: auto;

    ul {
      display: flex;
      margin-right: 1rem;
    }
  }
`

const Menu = styled.ul``

const MenuItem = styled.li`
  
  display:block;

  ${breakpoints[1]} {
    margin-left: 1rem;
    margin-right: 0rem;
  }

`

const MenuLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  border-radius: 5px;

  ${breakpoints[1]} {
    display: block;
    color: #fff;
    background-color: ${props => props.theme.colors.primary};
    margin-left: 1rem;
    margin-right: 0rem;
  }
`

const Hamburger = styled(FaBars)`
  color: white;
  flex: 0 0 2rem;
  font-size: 2rem;
  margin: 1rem;

  ${breakpoints[1]} {
    display: none;
  }
`

export default class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      open: !prevState.open
    }))
  }

  render() {
    return (
      <>
        <Wrapper>
          <H1>
            <Link
              to="/"
              style={{
                textDecoration: `none`,
              }}
            >
              jozzhart
            <span style={{
                fontWeight: "normal",
                paddingLeft: "3px"
              }}>.com</span>
            </Link>
          </H1>
          <Hamburger onClick={() => this.toggle()} />
          <Nav aria-label="main navigation" className={this.state.open ? 'open' : 'closed'}>
            <Menu role="menubar" aria-label="main navigation">
              {/* <MenuItem role="none">
            <MenuLink role="menuitem" to="/">Home</MenuLink>
          </MenuItem> */}
              <MenuItem role="none">
                <MenuLink role="menuitem" to="/">
                  Projects
              </MenuLink>
              </MenuItem>
              {/* <MenuItem role="none">
            <MenuLink role="menuitem" to="/contact/">About</MenuLink>
          </MenuItem> */}
              <MenuItem role="none">
                <MenuLink role="menuitem" to="/contact/">
                  Contact
              </MenuLink>
              </MenuItem>
            </Menu>
          </Nav>
        </Wrapper>
      </>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}