import {
  SquareSerifBookWOFF2,
  SquareSerifBookWOFF,
  SquareSerifBookTTF,
} from "../fonts"

import { css } from "@emotion/core"

import emotionNormalize from "emotion-normalize"

import theme from "./theme"

const breakpoints = [576, 768, 992, 1200]

const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

export default () => {
  return css`
    ${emotionNormalize}

    @font-face {
      font-family: "SquareSerifBook";
      font-weight: normal;
      font-style: normal;
      font-display: swap;
      src: url(${SquareSerifBookWOFF2}) format("woff2"),
        url(${SquareSerifBookWOFF}) format("woff"),
        url(${SquareSerifBookTTF}) format("truetype");
    }

    html {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    *,
    *:before,
    *:after {
      -webkit-box-sizing: inherit;
      -moz-box-sizing: inherit;
      box-sizing: inherit;
    }

    body {
      background-color: #0d355c;
      font-family: "SquareSerifBook";
      font-size: 18px;
      ${mq[1]} {
        font-size: 22px;
      }
    }

    h1 {
      font-size: 2rem;
    }

    h1,
    h1 a,
    h2,
    h3,
    h4,
    h5,
    h6 {
      // letter-spacing: -1px;
      color: ${theme.colors.primary};
    }

    p,
    li {
      color: ${theme.colors.primary};
    }

    a {
      color: ${theme.colors.quinary};
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    button {
      display: inline-block;
      border: none;
      padding: 1rem 2rem;
      margin: 0;
      text-decoration: none;
      background: ${theme.colors.primary};
      color: #ffffff;
      font-family: "SquareSerifBook";
      font-size: 1rem;
      cursor: pointer;
      text-align: center;
      transition: background 200ms ease-in-out, transform 150ms ease;
    }

    nav ul,
    nav li {
      margin: 0;
      padding: 0;
      text-indent: 0;
      list-style: none;
    }

    body.modal-open {
      height: 100vh;
      overflow: hidden;

      #portal {
        position: fixed;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 100;
      }
    }
  `
}
