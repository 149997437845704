/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { Global } from "@emotion/core"

import Header from "./header"
import Container from "./container"
import Main from "./main"
import Footer from './footer'

import { ThemeProvider } from "emotion-theming"

import theme from "../utils/theme"

import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import 'rc-pagination/assets/index.css';

import globalStyle from "../utils/globalStyles"

const globals = globalStyle

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <ThemeProvider theme={theme}>
        <Global styles={globals} />
        <Container>
          <Header siteTitle={data.site.siteMetadata.title} />
          <Main>{children}</Main>
        </Container>
        <Footer/>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
