import React from "react"
import styled from "@emotion/styled"
import Github from '../svg/Github.svg'
import Instagram from '../svg/Instagram.svg'
import LinkedIn from '../svg/Linkedin.svg'
import Flickr from '../svg/Flickr.svg'
import Vimeo from '../svg/Vimeo.svg'
import Twitter from '../svg/Twitter.svg'

import myImagePath from "../images/mountains.png"

import breakpoints from "../utils/breakpoints"

const OuterWrapper = styled.div`
  width: 100%;
  position: relative;

  border-top: 20px solid white;
  margin-top: -20px;

  ${breakpoints[0]} {
    border-top: 45px solid white;
    margin-top: -45px;
  }

  &:before {
    content: "";
    background-size: 1200px 186px;
    background-image: url(${myImagePath});
    background-position:  center bottom;
    background-repeat: no-repeat;
    height: 186px;
    width: 100%;
    display: block;
    position: absolute;
    z-index: 10;
    top: -186px;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.secondary};

    &:hover {
      color: white;
    }
  }


`

const Wrapper = styled.footer`
  margin: 0 auto;
  width: 100%;
  ${breakpoints[3]} {
    max-width: 1200px;
  }

  svg {
    max-width: 2.5rem;
    max-height: 2.5rem;
    display: block;
  }
`

const SocialLinks = styled.ul`
  list-style:none;
  display: flex;
  font-size: 2rem;
  flex-wrap: wrap;
  margin: 2rem 0 0rem 0;

  ${breakpoints[0]} {
    margin: 4rem 0 2rem 0;
  }

  li {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;

    a {
      display: flex;      
      align-items: center;
    }


    ${breakpoints[0]} {
      width: 50%;
    }

    ${breakpoints[3]} {
      width: 33.3%;
    }
  }
`

const Copyright = styled.div`
  text-align: center;
  color: white;
  padding: 1rem;
  font-size: 1rem;
  color: ${props => props.theme.colors.secondary};
`

const Footer = () => (
  <OuterWrapper>
    <Wrapper>
      <SocialLinks>
        <li>
          <a title="LinkedIn" href="https://www.linkedin.com/in/jozz-hart-526a9611"><LinkedIn />&nbsp; LinkedIn</a>
        </li>
        <li>
          <a title="GitHub" href="https://github.com/jozzhart/"><Github />&nbsp; Github</a>
        </li>
        <li>
          <a title="Instagram" href="https://www.instagram.com/jozzhart/"><Instagram />&nbsp; Instagram</a>
        </li>
        <li><a title="Flickr" href="http://www.flickr.com/photos/57309144@N00/"><Flickr />&nbsp; Flickr</a>
        </li>
        <li>
        <a title="Vimeo" href="http://vimeo.com/jozzhart"><Vimeo/>&nbsp; Vimeo</a>
        </li>
        <li>
        <a title="Twitter" href="http://twitter.com/jozzhart"><Twitter/>&nbsp; Twitter</a>
        </li>
      </SocialLinks>

      <Copyright>
        © 2011 - {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
      </Copyright>
    </Wrapper>
  </OuterWrapper>
)

export default Footer
